import React, {useContext, useEffect, useRef, useState} from 'react';
import {io} from 'socket.io-client';
import {DataContext} from '../../../context/dataContext';
import Processing from '../../../components/Processing/Processing';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {getUser} from '../../../utils/constants';
import {fetchDataFromAPI} from '../../../utils/api';
import {fetchDataFromAPIIMG} from '../../../utils/image';
import moment from 'moment';

const ChatPage = () => {
  // user id come from ChatUserPage.js
  const {id} = useParams();
  const user = JSON.parse(getUser());

  const navigate = useNavigate(); // To navigate back
  const location = useLocation();
  const {userData} = location?.state;

  // this is for dataContext
  const dataContext = useContext(DataContext);

  // send message state
  const [messageText, setMessageText] = useState('');

  // socker connection state
  const [isConnected, setIsConnected] = useState(false);

  // set message state
  const [messages, setMessages] = useState([]);

  const [page, setPage] = useState(0); // Track page for pagination
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  // socket url
  // const socket = io('https://staging1.infra360.org.uk:8080');
  const socket = io('https://preprod.transectra.com:8443');

  const getUsers = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPIIMG(
      `chat/getchathistory?userId=${userData?.userId?._id}&page=${page}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res ---->', res?.data);
        setMessages((prevMessages) =>
          [...res?.data, ...prevMessages]?.reverse(),
        );
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };
  useEffect(() => {
    getUsers(page); // Fetch initial messages
  }, []);

  // socket is connected
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
      if (socket.connected) {
        emitSocket();
        scrollToBottom();
        setIsConnected(true);
      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
      setIsConnected(false);
    });

    socket.on('receiveMessage', (message) => {
      console.log('Received message:', message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // return () => {
    //   socket.disconnect();
    //   console.log('Socket disconnected on component unmount');
    // };
  }, []);

  // emit method calls
  const emitSocket = () => {
    socket.emit('joinRoom', {
      userId: userData?.userId?._id,
      // senderRole: 'admin',
    });
  };

  // // message listerner
  // useEffect(() => {
  //   socket.on('receiveMessage', (message) => {
  //     console.log('object', message?.message);
  //     setMessages(message?.message);
  //     // setMessages([...messages, message?.message]);
  //   });
  // }, [messages]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // btn send message
  const sendMessage = () => {
    socket.emit('sendMessage', {
      message: messageText,
      userId: userData?.userId?._id,
      salesId: user?._id,
      sender: 'SELLER',
    });
    setMessageText('');
  };

  // Handle back button click
  const handleBack = () => {
    socket.disconnect(); // Disconnect the socket
    navigate(-1); // Go back to the previous page
  };

  // Handle scroll up event to load more messages
  const handleScroll = () => {
    if (chatContainerRef.current.scrollTop === 0) {
      // User has scrolled to the top, load more messages
      setPage((prevPage) => prevPage + 1);
      getUsers(page + 1);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  // Remove duplicate messages based on message ID
  const filteredMessages = messages?.filter((msg, index, self) => {
    return index === self.findIndex((m) => m._id === msg._id);
  });

  return (
    <>
      {dataContext?.loading && <Processing />}
      <div className="main" id="main">
        <div className="container-fluid d-flex flex-column vh-100">
          {/* Header */}
          <div
            className="row p-2 fixed-top main"
            id="main"
            style={{
              backgroundColor: isConnected ? '#BC923F' : '#6C757D',
              color: '#fff',
              zIndex: 10,
            }}>
            <div className="col d-flex align-items-center" onClick={handleBack}>
              <div role="button">
                <i className="bi bi-arrow-left fs-4  pe-3"></i>
              </div>
              <h6 className="mb-0">
                {userData?.userId?.first_name} -{' '}
                {isConnected ? 'Connected' : 'Disconnected'}
              </h6>
            </div>
          </div>

          {/* Chat Messages Area */}
          <div
            className="row flex-grow-1 overflow-auto p-3 bg-light"
            style={{
              marginTop: 132,

              // zIndex: 999,
              marginBottom: 60,
            }}
            onScroll={handleScroll}
            ref={chatContainerRef}>
            <div className="col">
              <div className="d-flex flex-column">
                {filteredMessages?.map((msg, index) => (
                  <>
                    <strong
                      className={`position-relative align-self-${
                        msg.sender === 'SELLER' ? 'end' : 'start'
                      } rounded`}>
                      {msg?.sender == 'SELLER'
                        ? 'Me'
                        : msg?.sender === 'USER'
                        ? msg?.userId?.first_name || 'User'
                        : msg?.salesId?.first_name || 'Sales'}
                    </strong>
                    <div
                      key={index}
                      className={`position-relative align-self-${
                        msg.sender === 'SELLER' ? 'end me-2' : 'start ms-2'
                      } px-3 py-2 mb-3 shadow-sm`}
                      style={{
                        backgroundColor:
                          msg.sender === 'SELLER' ? '#BC923F' : '#F1F3F9',
                        color: msg.sender === 'SELLER' ? '#fff' : '#333',
                        maxWidth: '70%',
                        alignSelf:
                          msg.sender === 'SELLER' ? 'flex-end' : 'flex-start',
                        borderRadius:
                          msg.sender === 'SELLER'
                            ? '1rem  0 1rem 1rem '
                            : '0 1rem 1rem  1rem',
                      }}>
                      <div className="p-0">{msg.message}</div>
                      <p
                        className={`mb-0 small`}
                        style={{
                          color: msg?.sender === 'SELLER' ? '#fff' : '#333',
                          bottom: '5px',
                          right: '10px',
                          fontSize: '0.75rem',
                        }}>
                        {moment(msg.createdAt).format('h:mm A')}
                      </p>
                    </div>
                  </>
                ))}
              </div>
              <div ref={messagesEndRef} />
            </div>
          </div>

          {/* Message Input Area */}
          <div className="row bg-white p-3 border-top fixed-bottom" id="main">
            <div className="col">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type a message..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  style={{
                    borderTopLeftRadius: '25px',
                    borderBottomLeftRadius: '25px',
                  }}
                />
                <button
                  className="btn"
                  onClick={() => sendMessage()}
                  style={{
                    backgroundColor: isConnected ? '#BC923F' : '#6C757D',
                    color: '#ffffff',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                  }}
                  type="button">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPage;
