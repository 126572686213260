import React, {useContext, useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import CommonButton from '../../../components/CommonButton/CommonButton';
import * as Yup from 'yup';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {fetchDataFromAPIIMG} from '../../../utils/image';
import ViewClientModel from '../../../components/ViewClientModel/ViewClientModel';

const EditUserPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const user = JSON.parse(getUser());

  const [deleteId, setDeleteId] = useState(null);
  const [getBuyRequest, setBuyRequest] = useState();
  const [getHoldDiamond, setHoldDiamond] = useState();
  const [orderList, setOrderList] = useState();
  const [updatingId, setUpdatingId] = useState(null);

  const [salesData, setSalesData] = useState();
  console.log('getHoldDiamond', getHoldDiamond);
  const [clientModel, setClientModel] = useState(false);
  const [clientData, setClientData] = useState();

  const state = useParams();
  console.log('state', state);

  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  useEffect(() => {
    buyRequest();
    holdDiamond();
    getOrders();
    getSalesDetails();
  }, []);

  const getSalesDetails = () => {
    fetchDataFromAPIIMG(`contact?id=${user?._id}`, 'get', '', user?.auth_token)
      .then((res) => {
        console.log('res sales', res);
        setSalesData(res?.data);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const buyRequest = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `getBuyerRequest?user_id=${state?.id}`,
      'get',
      '',
      user?.auth_token,
    )
      .then((res) => {
        console.log('res --->', res);
        setBuyRequest(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        dataContext?.setLoading(false);

        console.log('error', error);
      });
  };

  const holdDiamond = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPIIMG(
      `holddiamond?user_id=${state?.id}`,
      'get',
      '',
      user?.auth_token,
    )
      .then((res) => {
        console.log('res hold --->', res?.data);
        setHoldDiamond(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        dataContext?.setLoading(false);
        console.log('error hold -->', error);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`brand?id=${deleteId}`, 'delete', '', user?.auth_token)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        buyRequest();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const getOrders = () => {
    fetchDataFromAPIIMG(
      `PlaceOrder?user_id=${state?.id}`,
      'get',
      '',
      user?.auth_token,
    )
      .then((res) => {
        setOrderList(res?.data);
        console.log('res order list', res?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const updateStatus = (id, status) => {
    console.log('id', id);
    console.log('status', status);

    setUpdatingId(id);
    dataContext?.setLoading(true);

    const body = {
      status: status,
    };

    fetchDataFromAPIIMG(`PlaceOrder?id=${id}`, 'put', body, user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getOrders();
        setUpdatingId(null);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Status update error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setUpdatingId(null);
        dataContext?.setLoading(false);
      });
  };

  const clientModelShow = (item) => {
    setClientData(item);
    setClientModel(true);
  };

  const closeClientModel = () => {
    setClientModel(false);
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <section className="section">
        <div className="row">
          {salesData?.is_buyer_request === true && (
            <div
              className={salesData?.is_hold_request ? 'col-lg-6' : 'col-lg-12'}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    {getBuyRequest?.length} Buy Requests
                  </h5>
                  {/* Table with stripped rows */}
                  <div className="table-responsive">
                    <table className="table text-nowrap table-hover table-striped">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Buy Request ID</th>
                          <th scope="col">Comments</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getBuyRequest && getBuyRequest.length > 0 ? (
                          getBuyRequest?.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.buyRequestID}</td>
                              <td>{item?.comments ? item?.comments : '- '}</td>
                              <td>{item?.status}</td>
                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() => {
                                      navigate(
                                        `/view-buyer-request/${item?._id}`,
                                      );
                                    }}>
                                    <i class="bi bi-eye-fill"></i>
                                  </div>
                                  {/* <div
              role="button"
              className="delete-icon btn btn-sm btn-danger"
              onClick={() => {
                setDeleteId(item?._id);
              }}>
              <i className="bi bi-trash"></i>
            </div> */}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* End Table with stripped rows */}
                </div>
              </div>
              {/* <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Table with stripped rows</h5>
          
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Position</th>
                        <th scope="col">Age</th>
                        <th scope="col">Start Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Brandon Jacob</td>
                        <td>Designer</td>
                        <td>28</td>
                        <td>2016-05-25</td>
                      </tr>
                    </tbody>
                  </table>
            
                </div>
              </div> */}
            </div>
          )}

          {salesData?.is_hold_request === true && (
            <div
              className={
                salesData?.is_buyer_request ? 'col-lg-6' : 'col-lg-12'
              }>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    {getHoldDiamond?.length} Hold Diamond
                  </h5>

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Shape</th>
                          <th scope="col">Carat</th>
                          <th scope="col">Hours</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getHoldDiamond && getHoldDiamond.length > 0 ? (
                          getHoldDiamond?.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {item?.diamond_id?.shape
                                  ? item?.diamond_id?.shape
                                  : '-'}
                              </td>
                              <td>
                                {item?.diamond_id?.carat
                                  ? item?.diamond_id?.carat
                                  : '-'}
                              </td>
                              <td>{item?.hours}</td>
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/view-hold/${item?._id}`);
                                  }}>
                                  <i class="bi bi-eye-fill"></i>
                                </div>
                              </div>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" style={{textAlign: 'center'}}>
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {salesData?.is_order === true && (
          <div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Order's</h5>
                {orderList?.length === 0 ? (
                  <p className="text-center">No data available</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="row">No</th>
                          <th>Order ID</th>
                          <th>Carat</th>
                          <th>PCS</th>
                          <th>Amount</th>
                          <th>View Seller</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody key={Math.random()}>
                        {orderList?.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?._id?.slice(-10)}</td>
                            <td>{item?.size?.toFixed(2)}</td>
                            <td>{item?.total_psc?.toFixed(2)}</td>
                            <td>${item?.total_amt?.toFixed(2)}</td>
                            <td className="">
                              <div
                                className="edit-icon btn btn-sm btn-primary"
                                onClick={() => clientModelShow(item)}>
                                <i class="bi bi-eye-fill"></i>
                              </div>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                value={item?.status}
                                onChange={(e) =>
                                  updateStatus(item?._id, e.target.value)
                                }
                                disabled={updatingId === item?._id}>
                                <option value="Checking for availability">
                                  Checking for availability
                                </option>
                                <option value="Ready for payment">
                                  Ready for payment
                                </option>
                                <option value="Payment Confirmed">
                                  Payment Confirmed
                                </option>
                                <option value="Payment cancelled">
                                  Payment cancelled
                                </option>
                                <option value="Checking for QC">
                                  Checking for QC
                                </option>
                                <option value="QC Approved">QC Approved</option>
                                <option value="QC Cancelled">
                                  QC Cancelled
                                </option>
                                <option value="Ready for Shipment">
                                  Ready for Shipment
                                </option>
                                <option value="Delivered">Delivered</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      {/* <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Brandon Jacob</td>
                <td>Designer</td>
                <td>28</td>
                <td>2016-05-25</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Bridie Kessler</td>
                <td>Developer</td>
                <td>35</td>
                <td>2014-12-05</td>
              </tr>
            </tbody> */}
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      <ViewClientModel
        show={clientModel}
        onHide={closeClientModel}
        data={clientData}
      />

      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Buy Request"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default EditUserPage;
