import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import moment from 'moment';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {getUser} from '../../../utils/constants';
import {Pagination} from 'react-bootstrap';
import {fetchDataFromAPIIMG} from '../../../utils/image';
import fileDownload from 'js-file-download';

const UserPage = () => {
  const navigate = useNavigate();
  const [brand, setBrand] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const user = JSON.parse(getUser());
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getUserData(currentPage);
  }, [currentPage]);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getUserData = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `getAllUser?sales_executive_id=${user?._id}&page=${page}&search=${searchQuery}`,
      'get',
      '',
      user?.auth_token,
    )
      .then((res) => {
        console.log('res brand', res);
        setBrand(res?.data);
        setTotalPages(res?.pages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`brand?id=${deleteId}`, 'delete', '', user?.auth_token)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getUserData();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getUserData(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getUserData(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const getCsv = () => {
    fetchDataFromAPIIMG(
      `getuser?search=${searchQuery}&is_export=true`,
      'get',
      '',
      user?.auth_token,
    )
      .then((res) => {
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Sales Users</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mt-3 align-items-center">
                    <div className="col-md-6">
                      <div className="input-group ms-4 rounded-1 overflow-hidden">
                        <input
                          className=""
                          type="text"
                          name="query"
                          placeholder="Search"
                          title="Enter search keyword"
                          style={{
                            flex: 1,
                            padding: '8px',
                            border: '1px solid #ccc',
                          }}
                          value={searchQuery}
                          onChange={(event) =>
                            setSearchQuery(event.target.value)
                          }
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            getUserData();
                            // setCurrentPage(1);
                          }}
                          type="submit"
                          title="Search"
                          style={{
                            backgroundColor: '#BC923F',
                            color: 'white',
                            border: 'none',
                            padding: '8px 15px',
                          }}>
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex gap-2 ms-5 align-items-center">
                        <div onClick={() => getCsv()}>
                          {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                          {/* <i className="bi bi-share"></i> */}
                          <img
                            role="button"
                            style={{height: 20, width: 25}}
                            src={require('../../../assets/images/export.png')}
                            alt="logo"
                          />
                          {/* </CSVLink> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div></div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => navigate('/add-brand')}>
                        Add Brand
                      </button>
                    </div>
                  </div> */}
                  {/* <h5 className="card-title">Brand Data</h5> */}
                  {brand?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Account Type</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brand?.map((item, index) => (
                          <tr className="align-middle" key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.first_name}</td>
                            <td>{item?.last_name}</td>
                            <td>{item?.phone}</td>
                            <td>{item?.account_type}</td>

                            <td className="">
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/edit-sales-user/${item?._id}`);
                                  }}>
                                  <i className="bi bi-pencil-square"></i>
                                </div>
                                {/* <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  onClick={() => {
                                    setDeleteId(item?._id);
                                  }}>
                                  <i className="bi bi-trash"></i>
                                </div> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-3 cursor-pointer"
                  onClick={handlePrevious}
                  role="button">
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                brand?.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-3 cursor-pointer"
                    onClick={handleNext}
                    role="button">
                    Next
                  </p>
                )}
            </Pagination>
          )}
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Brand Data"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default UserPage;
