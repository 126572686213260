import BuyListPage from '../pages/Home/BuyListPage/BuyListPage';
import ChatPage from '../pages/Home/ChatPage/ChatPage';
import ChatUserPage from '../pages/Home/ChatUserPage/ChatUserPage';
import Dashboardpage from '../pages/Home/Dashboardpage/Dashboardpage';
import EditAdminProfile from '../pages/Home/EditAdminProfilePage/EditAdminProfile';
import HoldListPage from '../pages/Home/HoldListPage/HoldListPage';
import OrderList from '../pages/Home/OrderListPage/OrderList';
import SalesMarkup from '../pages/Home/SalesMarkupPage/SalesMarkup';
import UserAskPage from '../pages/Home/UserAskPage/UserAskPage';
import ViewAsking from '../pages/Home/UserAskPage/ViewAsking';
import UserBuyRequest from '../pages/Home/UserBuyRequestPage/UserBuyRequest';
import EditUserPage from '../pages/Home/UserPage/EditUserPage';
import UserPage from '../pages/Home/UserPage/UserPage';
import ViewBuyRequest from '../pages/Home/UserPage/ViewBuyRequest';
import ViewHoldRequest from '../pages/Home/UserPage/ViewHoldRequest';
import PrivateRoute from './RouteProtection';
const routes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <Dashboardpage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Sales User',
    path: '/sales-user',
    element: (
      <PrivateRoute>
        <UserPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Profile',
    path: '/edit-profile',
    element: (
      <PrivateRoute>
        <EditAdminProfile />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Sales User',
    path: '/edit-sales-user/:id',
    element: (
      <PrivateRoute>
        <EditUserPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Buy Request',
    path: '/user-buy-request',
    element: (
      <PrivateRoute>
        <UserBuyRequest />
      </PrivateRoute>
    ),
  },
  // {
  //   title: 'Sales Markup',
  //   path: '/sales-markup',
  //   element: (
  //     <PrivateRoute>
  //       <SalesMarkup />
  //     </PrivateRoute>
  //   ),
  // },

  {
    title: 'View Buy Request',
    path: '/view-buyer-request/:id',
    element: (
      <PrivateRoute>
        <ViewBuyRequest />
      </PrivateRoute>
    ),
  },
  {
    title: 'View Hold',
    path: '/view-hold/:id',
    element: (
      <PrivateRoute>
        <ViewHoldRequest />
      </PrivateRoute>
    ),
  },

  {
    title: 'User Asking',
    path: '/user-asking',
    element: (
      <PrivateRoute>
        <UserAskPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'View Asking',
    path: '/view-diamond/:id',
    element: (
      <PrivateRoute>
        <ViewAsking />
      </PrivateRoute>
    ),
  },

  {
    title: 'orderlist',
    path: '/order-list',
    element: (
      <PrivateRoute>
        <OrderList />
      </PrivateRoute>
    ),
  },

  {
    title: 'buylist',
    path: '/buy-list',
    element: (
      <PrivateRoute>
        <BuyListPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'holdlist',
    path: '/hold-list',
    element: (
      <PrivateRoute>
        <HoldListPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Chat-User',
    path: '/chat-user',
    element: (
      <PrivateRoute>
        <ChatUserPage />
      </PrivateRoute>
    ),
  },
  {
    title: 'Chat-Page',
    path: '/user-chat',
    element: (
      <PrivateRoute>
        <ChatPage />
      </PrivateRoute>
    ),
  },
];

export default routes;
