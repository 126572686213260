import React from 'react';

const OrderList = () => {
  return (
    <>
      <p></p>
    </>
  );
};

export default OrderList;
