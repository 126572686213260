import React from 'react';

const HoldListPage = () => {
  return (
    <>
      <p></p>
    </>
  );
};

export default HoldListPage;
