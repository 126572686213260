import React from 'react';

const BuyListPage = () => {
  return (
    <>
      <p></p>
    </>
  );
};

export default BuyListPage;
