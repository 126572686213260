import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(null);
  const location = useLocation();

  const routes = [
    {title: 'Dashboard', path: '/dashboard'},
    {title: 'Sales Users', path: '/sales-user'},
    {title: 'User Asking', path: '/user-asking'},
    // {title: 'Buy List', path: '/buy-list'},
    // {title: 'Order List', path: '/order-list'},
    // {title: 'Hold List', path: '/hold-list'},
    // {title: 'Chat User', path: '/chat-user'},
  ];

  const handleCollapse = (title) => {
    setOpenDropdown(openDropdown === title ? null : title);
  };

  useEffect(() => {
    routes.forEach((route, index) => {
      if (route.content) {
        route.content.forEach((subRoute, subIndex) => {
          if (subRoute.path === location.pathname) {
            setSelectedIndex(index);
            setSelectedSubIndex(subIndex);
            setOpenDropdown(route.title);
          }
        });
      } else {
        if (route.path === location.pathname) {
          setSelectedIndex(index);
          setSelectedSubIndex(null);
        }
      }
    });
  }, [location.pathname]);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {routes.map((val, index) => {
          // if (val.content) {
          //   const isOpened = openDropdown === val.title;

          //   return (
          //     <li className="nav-item" key={index}>
          //       <Link
          //         className={isOpened ? 'nav-link' : 'nav-link collapsed'}
          //         data-bs-target={`#${val.title.toLowerCase()}-nav`}
          //         data-bs-toggle="collapse"
          //         onClick={() => handleCollapse(val.title)}>
          //         <i
          //           className={
          //             val.title === 'Diamond'
          //               ? 'bi bi-gem'
          //               : val.title === 'Packages'
          //               ? 'bi bi-boxes'
          //               : val.title === 'Rapaport Prices'
          //               ? 'bi bi-tags-fill'
          //               : val.title === 'Company'
          //               ? 'bi bi-buildings'
          //               : 'bi bi-terminal'
          //           }></i>
          //         <span>{val.title}</span>
          //         <i className="bi bi-chevron-down ms-auto"></i>
          //       </Link>
          //       <ul
          //         id={`${val.title.toLowerCase()}-nav`}
          //         className={`nav-content collapse ${isOpened ? 'show' : ''}`}
          //         data-bs-parent="#sidebar-nav">
          //         {val.content.map((item, subIndex) => (
          //           <li key={subIndex}>
          //             <Link
          //               to={item.path}
          //               className={
          //                 selectedSubIndex === subIndex &&
          //                 selectedIndex === index
          //                   ? 'nav-link active'
          //                   : 'nav-link'
          //               }
          //               onClick={() => {
          //                 setSelectedIndex(index);
          //                 setSelectedSubIndex(subIndex);
          //               }}>
          //               <i className="bi bi-circle-fill"></i>
          //               <span>{item.title}</span>
          //             </Link>
          //           </li>
          //         ))}
          //       </ul>
          //     </li>
          //   );
          // }

          return (
            <li className="nav-item" key={index}>
              <Link
                className={
                  selectedIndex === index
                    ? 'nav-link active text-white'
                    : 'nav-link'
                }
                to={val.path}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedSubIndex(null);
                }}>
                {val.title === 'Dashboard' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-receipt text-white'
                        : 'bi bi-receipt'
                    }></i>
                ) : val.title === 'Sales Users' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-people text-white'
                        : 'bi bi-people'
                    }></i>
                ) : val.title === 'Sales Markup' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-tag text-white'
                        : 'bi bi-tag'
                    }></i>
                ) : val.title === 'User Asking' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-broadcast-pin text-white'
                        : 'bi bi-broadcast-pin'
                    }></i>
                ) : val.title === 'Chat User' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-chat-dots text-white'
                        : 'bi bi-chat-dots'
                    }></i>
                ) : null}
                <span>{val.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
